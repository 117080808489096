
import { decamelize } from "humps"
import { updateQuery } from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import CameraRessource from "@/components/widgets/ressources/CameraRessource"
import ProjectRessource from "@/components/widgets/ressources/ProjectRessource"
import WidgetForm from "@/components/widgets/WidgetForm"
import WidgetCredentials from "@/components/widgets/WidgetCredentials"

export default {
  name: "Widgets",
  components: {
    CameraRessource,
    ProjectRessource,
    WidgetForm,
    WidgetCredentials,
  },
  data() {
    return {
      options: {},
      widgets: [],
      total: 0,
      loading: false,
      sortBy: "inserted_at",
      sortDesc: true,
      optionsWatcherEnabled: false,
      isChangesLoading: false,
      ressourceComponents: {
        camera: "CameraRessource",
        project: "ProjectRessource",
      },
      headers: [
        {
          value: "active",
          text: "",
          class: "subtitle-1 table-header",
          visible: true,
          width: 30,
        },
        {
          value: "widgetType",
          text: this.$t("content.widgets.headers.widgetType"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
          sortable: false,
        },
        {
          value: "ressources",
          text: this.$t("content.widgets.headers.ressources"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "allowedOrigin",
          text: this.$t("content.widgets.headers.origin"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "settings",
          text: this.$t("content.widgets.headers.settings"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 220,
        },
        {
          value: "actions",
          text: "",
          class: "subtitle-1 table-header",
          visible: true,
          width: 50,
        },
      ],
      editedWidget: null,
      shownCredentialsWidget: null,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  watch: {
    options: {
      handler() {
        if (this.optionsWatcherEnabled) {
          this.getWidgets()
        }
        this.optionsWatcherEnabled = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getWidgets()
  },
  methods: {
    async getWidgets(params = {}) {
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = decamelize(sortBy[0])
        updateQuery(params)
        this.loading = true
        let response = await EvercamApi.widgets.index({
          authorization: this.accountStore.token,
          ...params,
          sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          limit: itemsPerPage,
          page: page,
        })

        this.widgets = response.items
        this.total = response.total
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
    isBoolean(value) {
      return typeof value === "boolean"
    },
    async deleteWidget(widget) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.widgets.delete_widget_title"),
          message: this.$t("content.widgets.delete_widget_message"),
        })
      ) {
        try {
          await EvercamApi.widgets.delete(widget.id)
          this.getWidgets()
        } catch (error) {
          this.$notifications.error({
            text: `${this.$t("content.widgets.delete_widgets_error")}`,
            error,
          })
        }
      }
    },
    async editWidget(item) {
      this.isChangesLoading = true
      try {
        this.editedWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    createWidget() {
      this.editedWidget = {
        settings: { width: 100, resolution: "%" },
        ressources: {},
      }
    },
    async showWidgetLink(item) {
      this.isChangesLoading = true
      try {
        this.shownCredentialsWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    changeWidgetState({ id, active }) {
      this.save({ id, active })
    },
    async save(w) {
      this.isChangesLoading = true
      let widget = structuredClone(w)
      try {
        if (widget.id) {
          this.formatRessources(widget.ressources)
          await EvercamApi.widgets.update(widget.id, widget)
        } else {
          const [provider, id] = this.getProvider(widget.ressources)
          this.shownCredentialsWidget = await provider(id, widget)
        }
        this.editedWidget = null
        await this.getWidgets()
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.load_widgets_error")}`,
          error,
        })
      } finally {
        this.isChangesLoading = false
      }
    },
    formatRessources(ressources) {
      for (let key in ressources) {
        ressources[`${key}Id`] = ressources[key]?.id
        ressources[key] = undefined
      }
    },
    getProvider(ressources) {
      if (ressources?.project) {
        return [EvercamApi.widgets.createProjectWidget, ressources.project.exid]
      } else if (ressources?.camera) {
        return [EvercamApi.widgets.createCameraWidget, ressources.camera.exid]
      } else {
        throw new Error("Wrong ressource type")
      }
    },
  },
}
