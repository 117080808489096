
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import BimCompareContainer from "@/components/bimCompare/BimCompareContainer"
import { WidgetType, RessourceByType } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"

export default {
  components: {
    RecordingsWidget,
    BimCompareContainer,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributes: {},
      component: null,
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
  },
  watch: {
    widget: {
      handler: "setWidget",
      deep: true,
    },
  },
  mounted() {
    this.setWidget()
  },
  methods: {
    async setWidget() {
      if (!this.widget || !this.isRessourceValid()) {
        this.component = "div"

        return
      }

      switch (this.widget.widgetType) {
        case WidgetType.Live:
          this.accountStore.isLiveViewWidget = true

          this.component = "RecordingsWidget"
          this.attributes = {
            key: this.widget.ressources.camera.exid,
            cameraId: this.widget.ressources.camera?.exid ?? "",
            refreshRate: (this.widget.settings.refresh ?? 5) * 1000,
            allowPlayPause: this.widget.settings.playpause ?? false,
            allowZoom: this.widget.settings.zoom ?? false,
            allowFullscreen: this.widget.settings.fullscreen ?? false,
            allowDownload: this.widget.settings.download ?? false,
            isEmbedded: this.isEmbedded,
          }
          break
        case WidgetType.Recording:
          this.accountStore.isLiveViewWidget = true

          this.component = "RecordingsWidget"
          this.attributes = {
            key: this.widget.ressources.camera.exid,
            cameraId: this.widget.ressources.camera.exid,
            delayBy: this.delayMilliseconds(),
            isDarkMode: this.widget.settings.darkMode ?? false,
            isSideBar: this.widget.settings.sidebar ?? false,
            isEmbedded: this.isEmbedded,
          }
          break
        case WidgetType.CameraBim:
          await this.cameraStore.selectCamera(
            this.widget.ressources.camera?.exid
          )

          this.component = "BimCompareContainer"
          this.attributes = {
            key: this.widget.ressources.camera?.exid,
            selectedCamera: this.cameraStore.selectedCamera,
            isDarkMode: this.widget.settings.darkMode ?? false,
            isTimelinePage: !(this.widget.settings.timeline ?? false),
            isEmbedded: this.isEmbedded,
          }
      }
    },
    isRessourceValid() {
      return (
        (RessourceByType.camera.includes(this.widget.widgetType) &&
          this.widget?.ressources?.camera) ||
        (RessourceByType.project.includes(this.widget.widgetType) &&
          this.widget?.ressources?.project)
      )
    },
    delayMilliseconds() {
      if (!this.widget.settings.hasDelay) {
        return 0
      }

      return this.$moment
        .duration(this.widget.settings.delay, this.widget.settings.delayUnit)
        .asMilliseconds()
    },
  },
}
