
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    widget: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
