
import NdaIcon from "@/components/NdaIcon"
import { ProjectFeatureFlag } from "@evercam/shared/types/project"

export default {
  name: "ProjectRessource",
  components: {
    NdaIcon,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isUnderNda() {
      return this.value.featureFlags.includes(ProjectFeatureFlag.NdaMc)
    },
  },
}
