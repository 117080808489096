
import { WidgetType, RessourceByType } from "@evercam/shared/types"
import ProjectsAutocomplete from "@/components/ProjectsAutocomplete"
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import LiveWidgetSettings from "@/components/widgets/settings/LiveWidgetSettings"
import RecordingsWidgetSettings from "@/components/widgets/settings/RecordingsWidgetSettings"
import BimCompareWidgetSettings from "@/components/widgets/settings/BimCompareWidgetSettings"
import WidgetPreview from "@/components/widgets/WidgetPreview"

export default {
  components: {
    ProjectsAutocomplete,
    CamerasAutocomplete,
    LiveWidgetSettings,
    RecordingsWidgetSettings,
    BimCompareWidgetSettings,
    WidgetPreview,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resolutionType: [
        { text: "%", value: "%" },
        { text: "px", value: "px" },
      ],
      settingsComponents: {
        [WidgetType.Live]: "LiveWidgetSettings",
        [WidgetType.Recording]: "RecordingsWidgetSettings",
        [WidgetType.CameraBim]: "BimCompareWidgetSettings",
      },
    }
  },
  computed: {
    widgetForm: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    ressourceType() {
      if (RessourceByType.camera.includes(this.widgetForm.widgetType)) {
        return "camera"
      } else {
        return "project"
      }
    },
    formatedWidgetTypes() {
      return Object.values(WidgetType)
    },
  },
  watch: {
    "widgetForm.widgetType"(_, oldValue) {
      if (!oldValue) {
        return
      }
      this.widgetForm.settings = {
        width: this.widgetForm.settings.width,
        resolution: this.widgetForm.settings.resolution,
      }
    },
  },
  methods: {
    setDialogState(event) {
      if (!event) {
        this.widgetForm = null
      }
    },
  },
}
