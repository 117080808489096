
import Textarea from "@/components/Textarea"

export default {
  components: {
    Textarea,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    embedCode() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `<div id="ec-container" style='width: ${this.widget.settings.width}${this.widget.settings.resolution}'><script src='${window.location.origin}/widget.js?widgetKey=${this.widget.widgetKey}&widgetId=${this.widget.widgetId}' async><\/script></div>`
    },
  },
  methods: {
    setDialogState(event) {
      if (!event) {
        this.$emit("close")
      }
    },
  },
}
