
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: "minute" },
        { text: this.$t("content.hours"), value: "hour" },
        { text: this.$t("content.days"), value: "day" },
      ],
    }
  },
  computed: {
    widget: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
